import React from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { useStepsContext } from 'client/app/apps/protocols/context/StepsProvider';
import useElementErrorParser from 'client/app/components/ElementPlumber/useElementErrorParser';
import { stringToMarkdown } from 'common/lib/markdown';
import { ElementErorrSeverity } from 'common/types/bundle';
import Colors from 'common/ui/Colors';
import { MarkdownPreview } from 'common/ui/components/MarkdownPreview';

export default function ProtocolInstanceErrors() {
  const { selectedStepErrors } = useStepsContext();
  const parseError = useElementErrorParser();
  return (
    <InputStepErrorListContainer>
      {[...(selectedStepErrors ?? [])]?.map((stepError, index) => (
        <InputStepErrorListItem key={index} severity={stepError.severity}>
          <Stack direction="row" alignItems="center" gap={3}>
            <StyledInfoOutlinedIcon severity={stepError.severity} />
            <InputStepErrorHeader variant="subtitle2" severity={stepError.severity}>
              Protocol {stepError.severity === 'error' ? 'Error' : 'Warning'}
            </InputStepErrorHeader>
          </Stack>
          <Stack gap={1}>
            <Typography variant="subtitle2">Summary</Typography>
            <MarkdownPreview
              key={index}
              markdown={stringToMarkdown(parseError(stepError.message))}
            />
          </Stack>
          <Stack gap={1}>
            <Typography variant="subtitle2">Details</Typography>
            <MarkdownPreview
              key={index}
              markdown={stringToMarkdown(parseError(stepError.details))}
            />
          </Stack>
        </InputStepErrorListItem>
      ))}
    </InputStepErrorListContainer>
  );
}

const InputStepErrorListItem = styled(ListItem, {
  shouldForwardProp: prop => prop !== 'severity',
})<{ severity: ElementErorrSeverity }>(({ severity, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(3),
  padding: theme.spacing(4, 5),
  backgroundColor: severity === 'error' ? Colors.ERROR_LIGHT_BG : Colors.WARNING_LIGHT_BG,
  borderRadius: '4px',
}));

const InputStepErrorListContainer = styled(List)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

const StyledInfoOutlinedIcon = styled(InfoOutlinedIcon, {
  shouldForwardProp: prop => prop !== 'severity',
})<{ severity: ElementErorrSeverity }>(({ severity, theme }) => ({
  color: severity === 'error' ? theme.palette.error.main : theme.palette.warning.dark,
}));

const InputStepErrorHeader = styled(Typography, {
  shouldForwardProp: prop => prop !== 'severity',
})<{ severity: ElementErorrSeverity }>(({ severity, theme }) => ({
  color: severity === 'error' ? theme.palette.error.main : theme.palette.warning.dark,
}));
