import React, { createContext, FC, useCallback, useContext } from 'react';

import { useMutation } from '@apollo/client';

import { SIMULATE_PROTOCOL_INSTANCE } from 'client/app/api/gql/mutations';
import { useProtocolInstanceContext } from 'client/app/apps/protocols/context/ProtocolInstaceProvider';
import useSimulationButton from 'client/app/apps/workflow-builder/useSimulationButton';
import { simulationRoutes } from 'client/app/lib/nav/actions';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';

type SimulationContextType = {
  isSimulating: boolean;
  onSimulate: () => Promise<void>;
  simulateButtonTooltipTitle: string;
  shouldDisableSimulateButton: boolean | undefined;
};

export const SimulationContext = createContext<SimulationContextType | undefined>(
  undefined,
);

export const useSimulationContext = () => {
  const context = useContext(SimulationContext);

  if (context === undefined) {
    throw new Error('useSimulationContext must be used within a SimulationProvider');
  }

  return context;
};

export const SimulationProvider: FC = ({ children }) => {
  const navigation = useNavigation();
  const [simulateProtocolInstance, { loading: isSimulating }] = useMutation(
    SIMULATE_PROTOCOL_INSTANCE,
  );
  const { protocolInstance, workflowConfig } = useProtocolInstanceContext();

  const onSimulate = useCallback(async () => {
    const response = await simulateProtocolInstance({
      variables: {
        input: {
          id: protocolInstance.id,
          editVersion: protocolInstance.editVersion,
        },
      },
    });
    const simulation = response.data?.simulateProtocolInstance.simulation;

    if (simulation) {
      if (simulation.errors.length === 0) {
        navigation.navigate(simulationRoutes.openInSimulationDetails, {
          simulationId: simulation.id,
        });
      }
    }
  }, [
    navigation,
    protocolInstance.editVersion,
    protocolInstance.id,
    simulateProtocolInstance,
  ]);

  const { simulateButtonTooltipTitle, shouldDisableSimulateButton } = useSimulationButton(
    {
      workflowConfig,
      DOETemplateMode: false, // TODO: No DOE for the first version of protocols
    },
  );

  const state = {
    isSimulating,
    onSimulate,
    simulateButtonTooltipTitle,
    shouldDisableSimulateButton,
  };

  return (
    <SimulationContext.Provider value={state}>{children}</SimulationContext.Provider>
  );
};
