import React from 'react';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { useStepsContext } from 'client/app/apps/protocols/context/StepsProvider';
import ProtocolInstanceErrors from 'client/app/apps/protocols/ProtocolInstanceErrors';
import {
  OutputPreview,
  OutputPreviewSkeleton,
} from 'client/app/apps/workflow-builder/output-preview/OutputPreview';
import Colors from 'common/ui/Colors';
import ContainerWithIntersectionBar from 'common/ui/components/ContainerWithIntersectionBar/ContainerWithIntersectionBar';

export const OutputStepPreview = () => {
  const { selectedStep, selectedStepErrors } = useStepsContext();
  const noOutput = selectedStep.outputs.length === 0;
  const hasError = !!selectedStepErrors;

  return (
    <Wrapper noOutput={noOutput} hasError={hasError}>
      <OutputPreviewTitle variant="h4">Preview Output</OutputPreviewTitle>
      <ContainerWithIntersectionBar dense noHeader content={<ProtocolInstanceErrors />} />
      <OutputStepPreviewContainer />
    </Wrapper>
  );
};

function OutputStepPreviewContainer() {
  const { selectedStep, selectedStepErrors } = useStepsContext();
  const noOutput = selectedStep.outputs.length === 0;
  const hasError = !!selectedStepErrors;
  if (hasError) {
    return null;
  }
  return noOutput ? (
    <NoOutputWrapper>
      <Typography fontStyle="italic">No Preview</Typography>{' '}
      {/** TODO - Style this better */}
    </NoOutputWrapper>
  ) : (
    <OutputPreviewWrapper>
      <OutputPreview />
    </OutputPreviewWrapper>
  );
}

export function OutputStepPreviewSkeleton() {
  return (
    <Wrapper noOutput={false} hasError={false}>
      <OutputPreviewTitle variant="h4">Preview Output</OutputPreviewTitle>
      <OutputPreviewSkeleton />
    </Wrapper>
  );
}

const Wrapper = styled('div', {
  shouldForwardProp: prop => prop !== 'noOutput' && prop !== 'hasError',
})<{ noOutput: boolean; hasError: boolean }>(({ theme, noOutput, hasError }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(5),
  borderRadius: theme.spacing(0, 3, 3, 0),
  border: `1px solid ${Colors.GREY_30}`,
  borderLeft: 'none',
  backgroundColor: noOutput ? Colors.GREY_10 : 'white',
  justifyContent: noOutput ? 'center' : 'normal',
  gap: hasError ? theme.spacing(5) : 0,
  width: '100%',
}));

const NoOutputWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const OutputPreviewTitle = styled(Typography)(() => ({
  fontWeight: 600,
}));

const OutputPreviewWrapper = styled('div')({
  height: '95%',
});
